<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="main">
    <div class="main_top">
      <div class="img_top" @click="$router.push('/')">
        <img src="@/img/homeLogo.png" alt="">
      </div>
      <div class="dotai">
        <router-link v-for="(i,d) in tabs" :key='d' :to="i.path" :class="i.name==action?'action':'mmm'">{{i.comment}}</router-link>
        <!-- <router-link to="">动态</router-link>
        <router-link to="">加入我们</router-link> -->
      </div>
    </div>
    
  </div>
  <router-view/>
  <div class="boorde">
    <div class="boorde_bottom">
      <div class="borde_top">
        <div class="borde_left">
         <!-- <div class="borde_position">
            关注我们：
            <img src="./img/weixin2.png"  @mouseenter="touchClose" @mouseleave="touchOpen"  v-if="show" alt="">
            <img src="./img/weixin.png"  @mouseenter="touchClose" @mouseleave="touchOpen" v-if="!show" alt="">
            <div class="absol" v-if="show">
              <div class="image_erwei"><img src="@/img/erweima.jpg" alt=""></div>
            </div>
          </div> -->
          <div>联系我们：hansteve@126.com</div>
        </div>
        <div class="img_top" @click="$router.push('/')">
          <img src="@/img/homeLogo.png" alt="">
        </div>
      </div>
      <div class="xian"></div>
      <div class="main_fuzhi">
        <div @click="$router.push('/')">首页</div>
        <div @click="$router.push('/dotai')">动态</div>
        <div @click="$router.push('/about')">关于我们</div>
      </div>
      <div class="jianjie">
          <br/>
        ICP备案 京ICP备09065099号-3
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      show: false,
      tabs: [
        {name:"alber",path:"/",comment:"首页"},
      //  {name:"alber",path:"/qingshui",comment:"授权"},
       // {name:"strength",path:"/strength",comment:"产品介绍"},
      //  {name:"copyright",path:"/copyright",comment:"定制"},
        {name:"dotai",path:"/dotai",comment:"动态"},
        {name:"about",path:"/about",comment:"关于我们"},
       ],
       action: ''
    }
  },
  watch: {
    $route(name) {
      console.log(name)
      this.action = name.name
    }
  },
  methods: {
    touchClose() {
      this.show = true
    },
    touchOpen() {
      this.show = false
      
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.main{
  width: 100%;
  margin: 0 auto;
  height: 6rem;
  background: #ffff;
  opacity: 0.9;
}
.main_top{
  width: 100rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dotai>a{
  margin-left: 5rem;
  /* color: #000; */
  text-decoration: none;
  font-size: 1.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  /*  */
  line-height: 1.83rem;
}
.img_top{
  width: 25.5rem;
  /* height: 2.67rem; */
  cursor: pointer;
}
.img_top>img{
  width: 100%;
  height: 100%;
}
.boorde{
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  /* overflow: hidden; */
  background: #F2F3F5;
  padding-bottom: 6.75rem;
}
.boorde_bottom{
  width: 100rem;
  margin: 0 auto;
  overflow: hidden;
}
.borde_top{
  padding-top: 3.08rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.borde_left>div{
  margin-top: 3.08rem;
  font-size: 1.83rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5F6464;
  line-height: 2.5rem;
}
.borde_left>div:nth-child(1){
  display: flex;
  align-items: center;
  margin-top: 0px;
}
.borde_left>div>img{
  /* margin-top: 0px; */
  width: 2.75rem;
  height: 2.17rem;
}
.xian{
  background: #999999;
  height: 0.08rem;
  width: 100%;
  margin-top: 2rem;
}
.main_fuzhi{
  margin-top: 2.58rem;
  display: flex;
  font-size: 1.83rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2E2E;
  line-height: 2.5rem;
}
.main_fuzhi>div{
  margin-left: 7.25rem;
  cursor: pointer;
}
.main_fuzhi>div:nth-child(1){
  margin-left: 0rem;
  
}
.jianjie{
  margin: 0 auto;
  /* width: 70%; */
  text-align: center;
  margin-top: 10.25rem;
  font-size: 1.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5F6464;
  line-height: 1.83rem;
}
.mmm{
  color: #5F6464;
}
.action{
  color: #FF9328;
}

.borde_position{
  position: relative;
}
.absol{
  width: 11.67rem;
  height: 12.5rem;
  position: absolute;
  top: -13rem;
  left: 4.75rem;
  background: url('@/img/bei.png') no-repeat;
  background-size: 100% 100%;
}
.image_erwei{
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  margin-top: 0.92rem;
}
.image_erwei>img{
  width: 100%;
  height: 100%;
  image-rendering: -moz-crisp-edges;
image-rendering: -o-crisp-edges;
image-rendering: -webkit-optimize-contrast;
image-rendering: crisp-edges;
-ms-interpolation-mode: nearest-neighbor;
}

</style>
